import { Box, colors, FlexProps, Grid, Heading, HStack } from "@biblioteksentralen/js-utils";
import { getSiteUrl } from "@libry-content/common";
import { Site } from "@libry-content/types";
import { useTranslation } from "../utils/hooks/useTranslation";
import onDesktop from "../utils/onDesktop";
import { ImagePreservingAspectRatio } from "./lists/ImagePreservingAspectRatio";

type Props = {
  sites?: Pick<Site, "_id" | "domain" | "municipality" | "name">[];
} & FlexProps;

export const LinksToAllSites = ({ sites, ...chakraProps }: Props) => {
  const { ts } = useTranslation();

  if (!sites?.length) return null;

  return (
    <Grid
      as="ul"
      gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
      gap="1rem 4rem"
      listStyleType="none"
      {...chakraProps}
    >
      {sites.map((site) => (
        <Box as="li" key={site._id}>
          <HStack
            as="a"
            height="100%"
            width="fit-content"
            _hover={onDesktop({ background: colors.grey05, textDecoration: "underline" })}
            transition="background 0.15s ease"
            padding={{ base: "0", md: "0.5rem 1rem" }}
            borderRadius="0.25rem"
            href={getSiteUrl(site)}
            textDecoration="none"
            justifyContent="flex-start"
            alignItems="center"
            gap=".4rem"
            minHeight="3.5rem"
          >
            <ImagePreservingAspectRatio
              boxShadow="none"
              borderRadius="none"
              image={site.municipality?.coatOfArms}
              resolution={200}
              width="40px"
              alt=""
            />
            <Heading fontWeight="400" as="h3" fontSize="md" textAlign="center" whiteSpace="nowrap">
              {ts(site.name)}
            </Heading>
          </HStack>
        </Box>
      ))}
    </Grid>
  );
};
